<template>
  <div class="sellerfaq">
    <!-- 购买流程 -->
    <!-- <ul class="shopStep">
      <li v-for="(shopItem, index) in stepList"
          :key="index">
        <div class="shopSvg">
          <svg width="50px"
               height="50px"
               viewBox="0 0 16 16"
               fill="currentColor"
               xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
                  :d="pathItem.pathD"
                  v-for="(pathItem, index) in shopItem.path"
                  :key="index"></path>
          </svg>
        </div>
        <div class="shopText">{{shopItem.name}}</div>
        <div class="shopImg"
             v-if="shopItem.bool">
          <img src="../../assets/img/step.png"
               alt="" />
        </div>
      </li>
    </ul> -->
    <!-- 常见问题 -->
    <div class="container">
      <h4>About Crediscounts:</h4>
      <p>Crediscounts is notably one of the fastest-growing communities for Amazon deals. We are consistently innovating, staying ahead of the curve in tech trends and product ideas. We select the best offers from Amazon and provide discounts and product testing opportunities exclusively for our users.</p>
      <h4>For Shopper</h4>
      <p>1. Save your time &money.</p>
      <p>We receive thousands of promotions from Amazon sellers every day. If you often start your shopping trip on Amazon, please follow us at crediscounts.com and you will find amazing offers on our platform</p>
      <p>2. Join us and make money easily! Participate in our promotions and drive group shopping to save even more. Recommend our platform to your friends,share offers and earn real cash rewards. The more you share, the more you earn!</p>
      <h4>For Seller</h4>
      <p>1. Offer great discounts and increase sales! The seller offers a large number of discount products at crediscounts, improve product sales, get positive comments, and improve store rankings!</p>
      <p>2. Find cooperation, promote products, and create explosive models! There are a lot of social influencers at crediscounts.com, contact them, get cooperation, let the influencers bring your products, your offers, and your services to everyone who needs them in the form of articles and videos!</p>
      <p>3. Join us and become a member of crediscounts. Crediscounts was created by a group of young and tasteful individuals whose mission is to help users improve their quality of life.</p>
      <h4>How It Works</h4>
      <h6>Seller share deals</h6>
      <p>Amazon sellers can share deals and coupons to buyer, which are all over 30% off.</p>
      <h6>Release products and get discounts</h6>
      <p>Our community has released the best deals from sellers, buyers can get them from crediscounts.com.</p>
      <h6>Sellers release free products</h6>
      <p>Buyers can get the product for free by simply applying for a test experience and sharing their real experience and let more friends enjoy the discount through crediscounts.com.</p>
      <!-- <el-collapse v-model="activeName"
                   accordion>
        <el-collapse-item :title="conItem.title"
                          :name="conItem.name"
                          v-for="(conItem, index) in containerList"
                          :key="index">
          <div>
            <p v-for="(pItem, index) in conItem.text"
               :key="index">{{pItem.textP}}</p>
          </div>
        </el-collapse-item>
      </el-collapse> -->
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      activeName: '1'
      // 流程列表
    //   stepList: [
    //     {
    //       bool: true,
    //       name: 'Product payment release',
    //       path: [
    //         {
    //           pathD:
    //             'M4 0h5.5v1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h1V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z'
    //         },
    //         {
    //           pathD:
    //             'M9.5 3V0L14 4.5h-3A1.5 1.5 0 0 1 9.5 3z'
    //         },
    //         {
    //           pathD:
    //             'M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z'
    //         }
    //       ]
    //     },
    //     {
    //       bool: true,
    //       name: 'seller creates product information',
    //       path: [
    //         {
    //           pathD:
    //             'M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l.5 2H5V5H3.14zM6 5v2h2V5H6zm3 0v2h2V5H9zm3 0v2h1.36l.5-2H12zm1.11 3H12v2h.61l.5-2zM11 8H9v2h2V8zM8 8H6v2h2V8zM5 8H3.89l.5 2H5V8zm0 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z'
    //         }
    //       ]
    //     },
    //     {
    //       bool: true,
    //       name: 'Buyer submits request',
    //       path: [
    //         {
    //           pathD:
    //             'M14.5 3h-13a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z'
    //         },
    //         {
    //           pathD:
    //             'M7 5.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0zM7 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z'
    //         }
    //       ]
    //     },
    //     {
    //       bool: false,
    //       name: 'Get the refund to your PayPal account',
    //       path: [
    //         {
    //           pathD:
    //             'M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1H2zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V7z'
    //         },
    //         {
    //           pathD:
    //             'M2 10a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1z'
    //         }
    //       ]
    //     }
    //   ]
      // 问题列表
    //   containerList: [
    //     {
    //       name: 1,
    //       title: '1.What is crediscounts?',
    //       text: [
    //         { textP: 'crediscounts is a discount platform for Amazon products,we collect the latest deals to help buyers save money. Every user can choose the products they would like to buy and purchase them on Amazon at the lowest price' }
    //       ]
    //     },
    //     {
    //       name: 2,
    //       title: '2.How do I get discounted deals on crediscounts?',
    //       text: [
    //         { textP: 'There are 4 easy steps to get any discounts on crediscounts' },
    //         { textP: 'Step 1: Create a crediscounts buyer account and complete your information.' },
    //         { textP: 'Step 2: Apply for the transaction request and contact the seller or wait for the seller’s approval.' },
    //         { textP: 'Step 3: After the seller approves your transaction request, buy the item on Amazon and upload your order information.' },
    //         { textP: 'Step 4: Seller confirms your order information and refunds the order money to your PayPal account accordingly.' }
    //       ]
    //     },
    //     {
    //       name: 3,
    //       title: '3.Why did my transaction request fail?',
    //       text: [
    //         { textP: '1.The number of product requests has reached the limit set by the seller.' },
    //         { textP: '2.Your available points are not enough to claim the deal.' }
    //       ]
    //     },
    //     {
    //       name: 4,
    //       title: '4.Which Amazon market does crediscounts support?',
    //       text: [
    //         { textP: 'Support US, UK, DE, FR, IT, CA, JP, ES, BR, IN, MX. The United States site has the most traffic, followed by Germany, the United Kingdom, Spain, and other European countries.' }
    //       ]
    //     },
    //     {
    //       name: 5,
    //       title: '5.Does crediscounts comply with the Amazon TOS Terms of Service?',
    //       text: [
    //         { textP: 'Sure, the crediscounts is fully compliant with Amazon’s platform rules. At the same time, third-party Amazon platforms are welcomed to bring in more high-quality off-site traffic to sellers’ stores and products.' }
    //       ]
    //     },
    //     {
    //       name: 6,
    //       title: '6.When will my money be transferred to my account?',
    //       text: [
    //         { textP: 'Usually, after the seller confirms that your order is valid, you will receive a refund to your PayPal account. You can check the refund status under My Deals.' }
    //       ]
    //     },
    //     {
    //       name: 7,
    //       title: '7.Will you cover the transaction fee charged by PayPal platform?',
    //       text: [
    //         { textP: 'No, we don’t pay PayPal. Besides, the customer may pay taxes and additional shipping costs on Amazon. You can negotiate with the seller about who will bear the transaction fees!' }
    //       ]
    //     },
    //     {
    //       name: 8,
    //       title: '8.What payment methods and refund methods do crediscounts support?',
    //       text: [
    //         { textP: 'We currently only support refunds for PayPal accounts. Only a small number of merchants will refund the discount volume or gift card at the request of the buyer.' }
    //       ]
    //     },
    //     {
    //       name: 9,
    //       title: '9.Do mechants have access to my information?',
    //       text: [
    //         { textP: 'Currently, we will only reveal your PayPal account and Amazon profile to sellers after you have made a purchase and uploaded your order info.' }
    //       ]
    //     },
    //     {
    //       name: 10,
    //       title: '10.Why was my account suspended?',
    //       text: [
    //         { textP: 'The reasons why we suspend your account may be as follows:' },
    //         { textP: '1.You have submitted the Amazon order and requested a refund on our site, but you still have requested a refund on Amazon.' },
    //         { textP: '2.Register multiple accounts to earn points by fraud.If your account does not have these behaviors during use, please feel free to contact us.' }
    //       ]
    //     }
    //   ]
    }
  }
}
</script>

<style lang="less" scoped>
.sellerfaq {
  text-align: left;
  background: #f2f2f2;
  padding: 30px 15px 50px 15px;
  .shopStep {
    background: white;
    display: flex;
    justify-content: space-between;
    padding: 30px 10px;
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .shopSvg {
        svg {
          color: #f93;
        }
      }
      .shopText {
        margin-left: 20px;
        font-size: 16px;
        line-height: 1.5;
      }
      .shopImg {
        margin-left: 40px;
      }
    }
  }
  .container {
    background: white;
    margin-top: 20px;
    h4 {
      font-size: 28px;
      padding: 26px 36px 10px 36px;
      color: #ff9933;
      font-weight: 500;
    }
    h6 {
      font-size: 22px;
      padding: 5px 36px;
      color: #ff9933;
      font-weight: 500;
      margin: 0;
    }
    p {
      font-size: 16px;
      padding: 10px 36px;
      margin: 0;
      font-weight: 100;
      line-height: 2;
    }
    //  .el-collapse-item__header {
    //    padding-left: 36px;
    //  }
    .el-collapse {
      .el-collapse-item {
        padding-left: 36px;
        border-bottom: 1px solid #ebeef5;
        /deep/ .el-collapse-item__header {
          border-bottom: 0px;
          position: relative;
          padding-left: 15px;
          font-size: 16px;
          font-weight: 600;
          /deep/ .el-collapse-item__arrow {
            position: absolute;
            left: 0;
            top: 18px;
            //  transform: translateY(-50%);
          }
        }
        /deep/ .el-collapse-item__wrap {
          border-bottom: 0px;
          padding-left: 20px;
        }
      }
    }
  }
}
</style>
